import React, {Component} from 'react'
import Cookies from 'js-cookie'
import querystring from 'querystring' // npm i querystring
import PropTypes from 'prop-types'
import config from '../config/index'
import {emailRegex} from '../utils/email'
export default class extends Component {
  static propTypes = {
    className: PropTypes.string,
    size: PropTypes.string,
  }

  static defaultProps = {
    className: '',
    size: 'md',
  }

  constructor(props) {
    super(props)
    this.state = {
      email: '',
      error: false,
    }
  }

  onChange = e => {
    this.setState({
      [e.currentTarget.name]: e.currentTarget.value,
    })
  }

  onSubmit = async e => {
    e.preventDefault()

    if (!this.state.email || !emailRegex.test(this.state.email)) {
      this.setState({
        error: true,
      })
      return
    }

    try {
      const body = querystring.stringify({
        email: this.state.email,
        isdisclosuresuser: true,
        lp_val_prop: 'A listing tool kit for real estate professionals',
        hompagecta: 'sign up now',
        hs_context: JSON.stringify({
          hutk: Cookies.get('hubspotutk'),
          pageUrl: document.referrer,
          pageName: document.title,
        }),
      })

      await fetch(`https://forms.hubspot.com/uploads/form/v2/${config.hubspot.signupPortal}/${config.hubspot.signupForm}`, {
        method: 'POST',
        body: body,
        mode: 'no-cors',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Content-Length': body.length,
        },
      })
    } catch (error) {
      console.error(error)
    }

    window.location = `https://app.disclosures.io/auth/signup?return_uri_email=${this.state.email}`
  }

  render() {
    return (
      <div className="">
        <div className="container grid-md pt-0 text-center" style={{marginBottom: '', marginTop: ''}}>
          <div className="columns">
            <div className="column container grid-xs">
              <form className={`box-shadow input-group ${this.props.className} ${this.state.error ? 'has-error' : ''}`} method="GET" onSubmit={this.onSubmit}>
                <input
                  type="email"
                  name="email"
                  className={` form-input ${this.props.size === 'sm' ? 'input-sm' : ''}`}
                  placeholder="Enter your email to start"
                  value={this.state.email}
                  onChange={this.onChange}
                  style={{borderRadius: '2px', paddingBottom: '4px'}}
                />
                <button
                  className={`box-shadow btn btn-primary ${this.props.size === 'sm' ? 'btn-sm' : ''} input-group-btn`}
                  style={{marginLeft: '5px', borderRadius: '2px'}}>
                  SIGN UP NOW{' '}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
