import React, {Component} from 'react'

export default class peerreviews extends Component {
  render() {
    return (
      <div class="bg-white pb-12 pt-4">
        <div className="container th-home--padmob zvideotop " style={{padding: '0rem 0 0rem 0'}}>
          <div className="container " style={{paddingBottom: '0rem'}}>
            <div className="text-center text-dark" style={{marginBottom: '1em'}}>
              <div className="h2  th-home--mobilefonth1 th-home--padmob">Testimonials</div>
              <div className="h4 th-home--mobilefonth4  " style={{marginTop: '1rem', color: '#00414b', fontWeight: '300'}}>
                Trusted by Transaction Coordinators, Assistants, Realtors, Brokers, & Brokerages
              </div>
            </div>
          </div>
          <div class="container grid-xl  ">
            <div class="columns  text-center" style={{padding: '20px', color: '#00414b'}}>
              <div class="column col-md-12 bg-transparent pb-6">
                <p style={{fontSize: '1rem', textAlign: 'center', marginBottom: '.5rem'}}>Award Winning</p>
                <p style={{fontSize: '.6rem', textAlign: 'center', marginBottom: '1rem'}}>2019 Innovation Award</p>
                <div className="video-responsive  " style={{color: '#00414b', boxShadow: ' 0px 0px 8px rgba(0, 0, 0, 0.1)'}}>
                  <iframe
                    src="https://player.vimeo.com/video/363416049?autoplay=0&title=0&byline=0&portrait=0"
                    title="Introduction Demo"
                    style={{borderRadius: '0px', boxShadow: ' 0px 0px 8px rgba(0, 0, 0, 0.1)'}}
                  />
                </div>
              </div>
              <div class="column col-md-12 bg-transparent pb-6">
                <p style={{fontSize: '1rem', textAlign: 'center', marginBottom: '.5rem'}}>Broker Owner Approved</p>
                <p style={{fontSize: '.6rem', textAlign: 'center', marginBottom: '1rem'}}>Gretchen Pearson, Owner BHHS Drysdale </p>
                <div className="video-responsive  " style={{color: '#00414b', boxShadow: ' 0px 0px 8px rgba(0, 0, 0, 0.1)'}}>
                  <iframe
                    width="640"
                    height="360"
                    src="https://player.vimeo.com/video/363439880?autoplay=0&title=0&byline=0&portrait=0"
                    title="Introduction Demo"
                    style={{borderRadius: '0px', border: '2px solid #000000', boxShadow: 'inset 0px 0px 0px 8px #000000 ', boxSizing: ' border-box'}}
                  />
                </div>
              </div>

              <div class="column col-md-12 bg-transparent pb-6">
                <p style={{fontSize: '1rem', textAlign: 'center', marginBottom: '.5rem'}}>Top Agent Recommended</p>
                <p style={{fontSize: '.6rem', textAlign: 'center', marginBottom: '1rem'}}>Butch Haze, Top Producer - San Francisco</p>
                <div className="video-responsive  " style={{color: '#00414b', boxShadow: ' 0px 0px 8px rgba(0, 0, 0, 0.1)'}}>
                  <iframe
                    src="https://player.vimeo.com/video/344350257?autoplay=0&title=0&byline=0&portrait=0"
                    title="Introduction Demo"
                    style={{borderRadius: '0px'}}
                  />
                </div>
              </div>
              {/* <div class="column col-md-12 bg-transparent pb-6">
                <p style={{fontSize: '1rem', textAlign: 'center', marginBottom: '.5rem'}}>Team Ready</p>
                <p style={{fontSize: '.6rem', textAlign: 'center', marginBottom: '1rem'}}>Wendy Moore, Owner The W.Moore Team</p>
                <div className="video-responsive  " style={{color: '#00414b', boxShadow: ' 0px 0px 8px rgba(0, 0, 0, 0.1)'}}>
                  <iframe
                    src="https://player.vimeo.com/video/361969265?autoplay=0&title=0&byline=0&portrait=0"
                    title="Introduction Demo"
                    style={{borderRadius: '0px'}}
                  />
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
