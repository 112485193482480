import React from 'react'
import {StaticQuery, graphql} from 'gatsby'
import SignupFormMob from '../components/SignupFormMob'
import SignupFormNav from '../components/SignupFormNav'
import SignupForm from '../components/SignupForm'

function Navbar({onClickMenu, className}) {
  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              appUrl
              logo {
                light
              }
            }
          }
        }
      `}
      render={data => {
        const appUrl = data.site.siteMetadata.appUrl
        const logoUrl = 'https://disclosures-cdn.s3-us-west-2.amazonaws.com/assets/hlm-logos-2021/listing-management-color-dark.png'
        return (
          <>
            <div className={`th-home--navbar th-home--navbar-container-index navbar container grid-xl pt-4 px-2 ${className || ''}`}>
              <div className="navbar-section ">
                <a href="/" style={{display: 'flex', alignItems: 'center', textDecoration: 'none'}}>
                  <img src={logoUrl} alt="HomeLight Listing Management (HLM)" style={{height: '2rem'}} />
                </a>
              </div>
              {/* THIS IS THE MOBILE DROPDOWN */}
              <div className="navbar-section grid-xl text-right text-large show-lg " style={{paddingRight: '2px'}}>
                <div className="dropdown dropdown-right ">
                  <a className="btn btn-action btn-primary dropdown-toggle " tabIndex="0" onClick={onClickMenu} style={{color: '#ffffff'}}>
                    <i className="icon icon-menu" />
                  </a>
                </div>

                {/* <div className="hide-md">
                  <a href="/demo" className="btn btn-link">
                    Watch a Demo <i className="fas fa-video" />
                  </a>
                  <a className="btn btn-link" href="/demo">
                    Learn More
                  </a>

                  <a className="btn btn-link" href={`${appUrl}/auth/login`}>
                    Login
                  </a>
                  <a className="btn btn-primary" href={`${appUrl}/auth/signup`} style={{padding: '.5em 4em 1em 4em'}}>
                    Signup
                  </a>
                </div> */}
              </div>

              {/* THIS IS THE MAIN DESKTOP NAV */}

              <div className="navbar-section hide-md hide-sm hide-lg">
                {/* <a className="nav-link text-white" href="/demo">
                  Learn More
                </a>
                <a
                  className="nav-link3 text-white"
                  href="https://itunes.apple.com/us/app/disclosures-io/id1435244080?mt=8"
                  target="_blank"
                  rel="noopener noreferrer"
                  universal="true">
                  <i className="fab fa-apple text-white" />
                  &nbsp; <span style={{textTransform: 'lowercase'}}>i</span>
                  OS App
                </a> */}

                <a href="/features" className="nav-link2 text-white">
                  Features
                </a>

                <a href="/agents" className="nav-link text-white">
                  <i class="fas fa-hands-helping"></i>
                  &nbsp; Services
                </a>

                <a className="nav-link2 text-white" href="/pricing">
                  Pricing
                </a>

                {/* <a>
                  <div class="hide-md" style={{marginLeft: '20px', marginTop: '1rem'}}>
                    <SignupFormNav size="md" />
                  </div>
                </a> */}
                <a className="nav-link2 text-white" href={`${appUrl}/auth/login`} style={{marginRight: '0px'}}>
                  Log In
                </a>

                <div className=" ">
                  <div className="">
                    <div className="">
                      <a href="#signup-mod " className="nav-link4 text-white">
                        Sign Up
                      </a>
                      <div className="modal modal-lg pt-20 text-primary signup-mods" id="signup-mod" style={{height: '100%'}}>
                        <a href="#" className=" modal-overlay text-primary" aria-label="Close" />
                        <div className="modal-container modaluni " role="document" style={{position: 'fixed', right: '0%', height: '100%'}}>
                          <a href="#" className=" text-right" aria-label="Close">
                            <i class="far fa-times-circle text-dark fa-lg"></i>
                          </a>
                          <div className="modal-body  text-center modal-body-uni text-dark">
                            <h3 class="pb-2 text-center">Getting started is easy, and free!</h3>
                            <p class="pb-6">Enter the email that you would like as your main account login. </p>
                            <div className="px-12">
                              <SignupFormMob size="md" />
                            </div>
                            <img
                              className=" img-responsive pt-16 container "
                              src="https://disclosures-cdn.s3-us-west-2.amazonaws.com/assets/hlm-logos-2021/listing-management-color-light.png"
                              alt="HomeLight Listing Management (HLM)"
                              width="300"
                              height="auto"
                              style={{maxWidth: '250px'}}
                            />
                            <p class="pt-12 text-small">
                              Already have an account?{' '}
                              <a href="https://app.disclosures.io/auth/login" target="_blank">
                                Log In Here
                              </a>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )
      }}
    />
  )
}

export default Navbar
