import React, {Component} from 'react'
import SignupFormindex from '../../components/SignupFormindex'

export default class signupnow4 extends Component {
  render() {
    return (
      <div className=" pt-4 px-4 signup-bar bg-dark " style={{}}>
        <div className="container grid-xl ">
          {/* <div className="text-center">
            <div className="h1 th-font-av th-home--mobilefonth1 " style={{color: '#000000'}}>
              Spend more time with motivated buyers.
            </div>
            <div className="h4 th-home--mobilefonth4" style={{marginTop: '1.2rem', color: '#00414b', marginBottom: '2rem'}}>
              Buyer Interest Reports help agents identify which buyers are ready to make an offer
            </div>
          </div> */}
        </div>

        <div class="container grid-xl ">
          <div class=" bg-transparent th-home--deskhid ">
            <p className="text-white text-left " style={{fontSize: '.9em'}}>
              Impressive Listing Management tools. Perfect for every real estate professional. &nbsp;
              <a href="/signup" style={{color: '#ffec46', textDecoration: 'underline'}}>
                Sign up for free
              </a>
              .
            </p>
          </div>
          <div class=" bg-transparent th-home--mobhid ">
            <p className="text-white text-left " style={{fontSize: '.9em'}}>
              Impressive Listing Management tools. Perfect for every real estate professional. &nbsp;
              <a href="#signup-mod" style={{color: '#ffec46', textDecoration: 'underline'}}>
                Sign up for free
              </a>{' '}
              &nbsp; or&nbsp;{' '}
              <a href="/pricing" style={{textDecoration: 'underline'}}>
                continue exploring
              </a>
              .
            </p>
          </div>{' '}
          {/* <div class="columns ">
            <div class=" column col-sm-12 col-md-12 col-lg-12 col-xl-6  bg-transparent ">
              <p className="text-white th-home--splash-bar text-center " style={{fontSize: '.9em'}}>
                Impressive Listing Management tools, perfect for your current workflow. <a href="#signup-mod ">Sign up for free! </a>
              </p>
            </div>{' '}
            <div class="column col-sm-12 col-md-12 col-lg-12 col-xl-6 bg-transparent text-center " style={{borderRadius: '0px', border: '0px solid #000000'}}>
              <SignupFormindex size="sm" />
            </div>
          </div> */}
        </div>
      </div>
    )
  }
}
