import React, {Component} from 'react'
import SignupForm from '../SignupForm'

export default class Usercount extends Component {
  render() {
    return (
      <div className="py-0  container bg-white" style={{}}>
        <div className="container grid-xl " style={{padding: '.5rem 0 .5rem 0'}}>
          <div class="container py-20 " style={{marginTop: ''}}>
            <div class="columns text-center">
              <div class="column col-md-12 bg-transparent " style={{borderRadius: '0px', border: '0px solid #000000', margin: '5px'}}>
                <div class="container" style={{maxWidth: '100%'}}>
                  <i class="fas fa-user-friends text-primary fa-lg py-4" />
                </div>
                <h2 style={{fontSize: '2rem', textAlign: 'center', fontFamily: 'Avenir'}}>100k</h2>
                <p className="text-dark" style={{fontSize: '1rem', textAlign: 'center', lineHeight: '1.3em'}}>
                  Agents
                </p>
              </div>{' '}
              <div class="column col-md-12 bg-transparent " style={{borderRadius: '0px', border: '0px solid #000000', margin: '5px'}}>
                <div class="container" style={{maxWidth: '100%'}}>
                  <i class="fas fa-user-clock text-primary fa-lg py-4" />
                </div>
                <h2 style={{fontSize: '2rem', textAlign: 'center', fontFamily: 'Avenir'}}>8.5k</h2>
                <p className="text-dark" style={{fontSize: '1rem', textAlign: 'center', lineHeight: '1.3em'}}>
                  Transaction Coordinators
                </p>
              </div>{' '}
              <div class="column col-md-12 bg-transparent " style={{borderRadius: '0px', border: '0px solid #000000', margin: '5px'}}>
                <div class="container" style={{maxWidth: '100%'}}>
                  <i class="fas fa-store text-primary fa-lg py-4" />
                </div>
                <h2 style={{fontSize: '2rem', textAlign: 'center', fontFamily: 'Avenir'}}>500+</h2>
                <p className="text-dark" style={{fontSize: '1rem', textAlign: 'center', lineHeight: '1.3em'}}>
                  Brokerages
                </p>
              </div>{' '}
              <div class="column col-md-12 bg-transparent " style={{borderRadius: '0px', border: '0px solid #000000', margin: '5px'}}>
                <div class="container" style={{maxWidth: '100%'}}>
                  <i class="fas fa-home text-primary fa-lg py-4" />
                </div>
                <h2 style={{fontSize: '2rem', textAlign: 'center', fontFamily: 'Avenir'}}>120k</h2>
                <p className="text-dark" style={{fontSize: '1rem', textAlign: 'center', lineHeight: '1.3em'}}>
                  Homes Sold, Faster.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
