import React, {Component} from 'react'
import SignupForm from '../../components/SignupForm'
import {nominalTypeHack} from 'prop-types'

export default class Propinfo extends Component {
  state = {
    open: false,
  }

  toggleModalvid = () => {
    this.setState({
      open: !this.state.open,
    })
  }

  render() {
    return (
      <div className=" th-home--mobiletopplay ">
        <div className="container grid-xl  text-left" style={{marginTop: '0rem', marginBottom: '0rem', marginLeft: '-.3rem'}}>
          <div className="columns">
            <div className=" container ">
              {/* DESKTOP BELOW */}{' '}
              <a
                className="btn btn-primary btn-lg th-home--mobhid"
                style={{
                  border: '0px #ffffff solid',
                  fontSize: '.9em',
                  borderRadius: '3px',
                  padding: '6px 10px',
                }}
                onClick={this.toggleModalvid}>
                <i className="fa fa-play fa-lg" style={{border: '0px solid #ffffff', padding: '10px 7px 8px 8px', borderRadius: '100px'}} /> Watch our short
                demo &nbsp;
              </a>
              {/* MOBILE BELOW */}
              <a
                className="btn btn-primary btn-lg th-home--deskhid"
                style={{
                  border: '0px #ffffff solid',
                  fontSize: '1em',
                  borderRadius: '3px',
                  padding: '3px 10px',
                }}
                onClick={this.toggleModalvid}>
                <i className="fa fa-play fa-md" style={{border: '0px solid #ffffff', padding: '8px 0px 8px 8px', borderRadius: '100px'}} /> &nbsp; WATCH THE
                DEMO&nbsp;
              </a>
              {this.state.open && (
                <div className="modal active pt-16" id="share-mod">
                  <a className="modal-overlay" aria-label="Close" onClick={this.toggleModalvid} />
                  <div className="modal-container py-2" role="document">
                    <div className="modal-body bg-white ">
                      <div className="video-responsive video-responsive-4-3" style={{color: '#00414b', marginTop: '-3rem', marginBottom: '-4rem'}}>
                        <iframe
                          className="px-8"
                          width="640"
                          height="360"
                          src="https://player.vimeo.com/video/347112369?autoplay=0&title=0&byline=0&portrait=0"
                          title="Introduction Demo"
                        />
                      </div>

                      <SignupForm />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}
