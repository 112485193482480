import React, {Component} from 'react'

export default class howandwhy extends Component {
  render() {
    return (
      <div class="bg-white">
        <div className="container " style={{}}>
          <div className="bg-white ">
            <div className="container grid-xl py-8 px-20 hlm-howwhy-mob">
              <div className="h2 th-home--mobilefonth2 th-home--padmob pt-4 pb-2 text-center">How HLM works</div>
              <div className="p th-home--mobilefontp-sm th-home--padmob pt-4 pb-2 text-left ">
                With HomeLight Listing Management, real estate professionals can manage, market, and sell listings in one secure location. Most importantly, HLM
                helps agents create a consistent experience for every client. Drive competition and receive non-contingent offers faster with HomeLight Listing
                Management.
              </div>
              {/* <div className="p th-home--mobilefonth4 th-home--padmob pb-2">
                <ul>
                  <li> Manage everything in one secure location</li>
                  <li> Keep clients well informed</li>
                  <li> Create a consistent experience for your clients</li>
                </ul>
              </div> */}

              <img
                className=" size-full pl-12 th-home--mobhid"
                src="https://disclosures-cdn.s3-us-west-2.amazonaws.com/assets/Marketing/ios+images/share+property+phone+updates.png"
                alt=""
                width="380"
                height="auto"
                style={{float: 'right'}}
              />

              <div className="h5 th-home--mobilefonth4 th-home--padmob pt-2 py-2">This tool is effective!</div>
              <div className="p th-home--mobilefontp-sm th-home--padmob pb-2 ">
                HomeLight Listing Management (HLM) helps real estate teams educate interested buyers with an easy-to-read and professionally organized Listing
                Information Package.
              </div>
              <div className="p th-home--mobilefontp-sm th-home--padmob pb-2 ">
                Create a private or public Listing Information Package and share it with interested buyer parties. Near instantly, you and your team will
                receive Buyer Interest Reports explaining the: open, read, and %-read of each document by each buyer party.
              </div>
              <div className="h5 th-home--mobilefonth4 th-home--padmob pt-2 py-2">Help buyers make purchase decisions faster</div>
              <div className="p th-home--mobilefontp-sm th-home--padmob pb-2 ">
                As conversations with potential buyers quickly turn to price and speed, real estate teams can provide the proper attention to the most
                interested parties.
              </div>
              <div className="p th-home--mobilefontp-sm th-home--padmob pb-2 ">
                From each listing information package, you'll be able to see offers coming well before they arrive.
              </div>

              <div className="h5 th-home--mobilefonth4 th-home--padmob pt-2 py-2">With an impressive suite of listing management tools</div>
              <div className="p th-home--mobilefontp-sm th-home--padmob pb-2">
                Create digital Listing Information Packages your way. Either private or public, a “Listing Information Package” contains the supplemental
                documents that buyers won't find on real estate portals like Zillow. HLM keeps your listing-info-package secure. Your packet will never end up
                on google. You share it with whomever you want to.
              </div>

              <div className="h5 th-home--mobilefonth4 th-home--padmob pt-2 py-2">It's easy and free to use</div>
              <div className="p th-home--mobilefontp-sm th-home--padmob pb-2">
                If you've secured a listing, you have everything you need. A Listing Information Package should include all of the supplemental documents you
                have on your listing (e.g., Sellers Disclosures, Property Inspection Reports, HOA Articles, Property Brochure, Pest Inspection Report, Floor
                Plan, etc.).
              </div>
              <div className="p th-home--mobilefontp-sm th-home--padmob pb-2 ">
                The HomeLight Listing Management (HLM) Basic Membership is completely free. You’ll be able to do everything you do now to sell a home in fewer
                steps and in one secure location — for free.
              </div>
              <div className="p th-home--mobilefontp-sm th-home--padmob pb-2 ">
                The Pro Membership is an additional set of features that enables agents to see the activity of each buyer party. It also unlocks a whole suite
                of communication tools that make it easy to keep buyers and sellers engaged with the deal.
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
